// import store from '@/store'
// 有 name 属性的都是缓存组件
// isPublic 意味着只要登录成功就能看到的页面
import { fetchDetail } from "@/api/epc/income-expense/account";
export default [
  {
    path: "/register",
    component: () => import("@/views/register"),
  },
  {
    path: "/login",
    component: () => import("@/views/login"),
  },

  {
    path: "/",
    component: () => import("@/layout"),

    // beforeEnter: (to, from, next) => {

    //   console.log('to', to)
    //   console.log('from', from)

    //   const token = window.localStorage.getItem("token");


    //   if (!token) {
    //     // 未登录直接路由到登录页

    //     if (from.path === "/login") {
    //       next(false);
    //       return;
    //     }
    //     next({
    //       path: "/login",
    //       query: {
    //         redirect: to.fullPath,
    //       },
    //     });
    //     return;

    //   }

    //   if (to.meta.isPublic) {
    //     next();
    //     return;
    //   }
    //   const menuList = window.localStorage.getItem("menuList");
    //   if (menuList) {
    //     const menus = JSON.parse(menuList);
    //     const res = menus.find((item) => item.path === to.path);

    //     console.log('before route check menu', res)

    //     if (res) {
    //       next();
    //     } else {
    //       next("/403");
    //     }
    //   } else {
    //     console.log("local storage don't has menu list");
    //     next("/403");
    //   }
    // },



    children: [
      {
        path: "",
        redirect: "/workplace",
      },
      // 工作台
      {
        path: "workplace",
        component: () => import("@/views/desk"),
        meta: {
          isPublic: true,
        },
      },
      {
        path: "dataBoard",
        component: () => import("@/views/dataBoard"),
      },
      {
        path: "dataBoard/talent",
        component: () => import("@/views/dataBoard/talent"),
      },
      {
        path: "dataBoard/edit",
        component: () => import("@/views/dataBoard/edit"),
      },
      {
        path: "dataBoard/editMonth",
        component: () => import("@/views/dataBoard/editMonth"),
      },
      {
        path: "dataBoard/bid",
        component: () => import("@/views/dataBoard/bid"),
      },
      {
        path: "dataBoard/bid/add",
        component: () => import("@/views/dataBoard/bidAdd"),
      },
      {
        path: "dataBoard/bid/edit",
        component: () => import("@/views/dataBoard/bidEdit"),
      },
      {
        path: "dataBoard/personDetail",
        component: () => import("@/views/dataBoard/personDetail"),
      },
      {
        path: "dataBoard/money",
        component: () => import("@/views/dataBoard/money/index.vue"),
      },
      {
        path: "dataBoard/money/add",
        component: () => import("@/views/dataBoard/money/add.vue"),
      },
      {
        path: "dataBoard/money/edit",
        component: () => import("@/views/dataBoard/money/edit.vue"),
      },
      {
        path: "dataBoard/talent/edit",
        component: () => import("@/views/dataBoard/talentEdit.vue"),
      },
      // 通讯录
      {
        path: "address-list",
        component: () => import("@/views/address-list"),
        meta: {
          isPublic: true,
        },
      },
      // 消息中心
      {
        path: "/message-center",
        component: () => import("@/views/message-center"),
        meta: {
          isPublic: true,
        },
      },

      // 个人设置页面
      {
        path: "personal-setting",
        component: () => import("@/views/personal/setting"),
        meta: {
          isPublic: true,
        },
      },

      // 新闻管理
      {
        path: "document",
        component: () => import("@/views/document"),
      },
      {
        name: "addDocument",
        path: "document/add",
        component: () => import("@/views/document/add"),
      },
      {
        name: "editDocument",
        path: "document/edit",
        component: () => import("@/views/document/edit"),
      },
      {
        path: "document/detail",
        component: () => import("@/views/document/detail"),
      },

      // 生产管理：合同管理
      {
        name: "contract",
        path: "produce/contract",
        component: () => import("@/views/contract"),
      },
      {
        name: "addContract",
        path: "produce/contract/add",
        component: () => import("@/views/contract/add"),
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
      },
      {
        name: "editContract",
        path: "produce/contract/edit",
        component: () => import("@/views/contract/edit"),
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
      },
      {
        path: "produce/contract-adjust",
        component: () => import("@/views/contract/adjust/index.vue"),
      },
      {
        path: "produce/contract-adjust/add",
        component: () => import("@/views/contract/adjust/add"),
      },
      {
        path: "produce/contract-adjust/detail",
        component: () => import("@/views/contract/adjust/detail"),
      },
      {
        path: "produce/contract/detail",
        component: () => import("@/views/contract/detail"),
      },

      // 合同规模
      {
        path: "produce/contract/scale",
        component: () => import("@/views/contract/scale"),
      },
      // 合同收款项
      {
        path: "produce/contract/collection",
        component: () => import("@/views/contract/collection"),
      },


      {
        path: "produce/contract/undertaking/add",
        component: () => import("@/views/contract/undertaking/add"),
      },
      {
        path: "produce/contract/undertaking/edit",
        component: () => import("@/views/contract/undertaking/edit"),
      },

      // 生产管理：进度分配
      {
        name: "progress",
        path: "produce/progress",
        component: () => import("@/views/progress"),
      },
      {
        path: "produce/progress/analysis",
        component: () => import("@/views/progress/analysis/index.vue"),
      },
      {
        name: "progressDetail",
        path: "produce/progress/detail",
        component: () => import("@/views/progress/detail"),
      },
      {
        name: "progressConfig",
        path: "produce/progress/config",
        component: () => import("@/views/progress/config"),
      },

      {
        path: "produce/progress-edit",
        component: () => import("@/views/progress-edit"),
      },


      {
        name: "progressAllocate",
        path: "produce/progress/allocate",
        component: () => import("@/views/progress/allocate"),
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
      },
      {
        name: "progressAllocate2021",
        path: "produce/progress/allocate2021",
        component: () => import("@/views/progress/allocate2021"),
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
      },
      {
        name: "progressAllocate2022",
        path: "produce/progress/allocate2022",
        component: () => import("@/views/progress/allocate2022"),
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
      },

      {
        name: "progressallocate2023",
        path: "produce/progress/allocate2023",
        component: () => import("@/views/progress/allocate2023"),
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
      },
      {
        name: "progressallocate2024",
        path: "produce/progress/allocate2024",
        component: () => import("@/views/progress/allocate2024"),
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
      },
      {
        // 专业负责人分配
        name: "majorAllocate",
        path: "produce/progress/major-allocate",
        component: () => import("@/views/progress/major-allocate"),
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
      },
      {
        // 调整阶段产值分配
        name: "adjust-allocate",
        path: "produce/progress/adjust-allocate",
        component: () => import("@/views/progress/adjust-allocate"),
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
      },

      {
        // 调整阶段-专业负责人分配
        name: "adjust-major-allocate",
        path: "produce/progress/adjust-major-allocate",
        component: () => import("@/views/progress/adjust-major-allocate"),
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
      },

      {
        path: "produce/progress/income-expenditure",
        component: () => import("@/views/progress/income-expenditure"),
      },

      {
        path: "produce/progress/edit-progress",
        component: () => import("@/views/progress/edit-progress"),
      },

      // 计划填报
      {
        path: "produce/progress/plan",
        component: () => import("@/views/progress/plan"),
      },
      {
        path: "produce/progress/plan/detail",
        component: () => import("@/views/progress/plan/detail"),
      },
      {
        path: "produce/progress/plan/confirm",
        component: () => import("@/views/progress/plan/confirm"),
      },
      {
        path: "produce/progress/plan/edit",
        component: () => import("@/views/progress/plan/edit"),
      },

      {
        path: "produce/progress/plan/year",
        component: () => import("@/views/progress/plan/year"),
      },
      {
        path: "produce/progress/plan/month",
        component: () => import("@/views/progress/plan/month"),
      },
      {
        path: "produce/progress/projectFin",
        component: () => import("@/views/progress/projectFin"),
      },
      {
        path: "produce/progress/projectFin/detail",
        component: () => import("@/views/progress/projectFin/detail"),
      },
      {
        name: "overview",
        path: "produce/overview",
        component: () => import("@/views/progress/overview"),
      },
      {
        path: "produce/overview/detail",
        component: () => import("@/views/progress/overview/detail"),
      },

      // 合同登记
      {
        name: "contract-register",
        path: "produce/contractRegister/review",
        component: () => import("@/views/contract-register"),
      },
      {
        name: "addContractRegister",
        path: "produce/contractRegister/review/add",
        component: () => import("@/views/contract-register/add"),
      },
      {
        name: "signContractRegister",
        path: "produce/contractRegister/review/sign",
        component: () => import("@/views/contract-register/sign"),
      },
      {
        path: "produce/contractRegister/review/detail",
        component: () => import("@/views/contract-register/detail"),
      },

      // 分包登记
      {
        name: "subpackage-register",
        path: "produce/contractRegister/subpackage",
        component: () => import("@/views/subpackage-register"),
      },
      {
        name: "addSubpackageRegister",
        path: "produce/contractRegister/subpackage/add",
        component: () => import("@/views/subpackage-register/add"),
      },
      {
        name: "signSubpackageRegister",
        path: "produce/contractRegister/subpackage/sign",
        component: () => import("@/views/subpackage-register/sign"),
      },
      {
        path: "produce/contractRegister/subpackage/detail",
        component: () => import("@/views/subpackage-register/detail"),
      },

      // 框架类合同登记
      {
        name: "framework-contract-register",
        path: "produce/contractRegister/framework",
        component: () => import("@/views/framework-contract-register"),
      },
      {
        name: "addFrameworkContractRegister",
        path: "produce/contractRegister/framework/add",
        component: () => import("@/views/framework-contract-register/add"),
      },
      {
        path: "produce/contractRegister/framework/detail",
        component: () => import("@/views/framework-contract-register/detail"),
      },
      {
        name: "signFrameworkContractRegister",
        path: "produce/contractRegister/framework/sign",
        component: () => import("@/views/framework-contract-register/sign"),
      },

      // 财务管理：发票管理
      {
        name: "invoice",
        path: "finance/invoice",
        component: () => import("@/views/invoice"),
      },
      {
        name: "applyInvoice",
        path: "finance/invoice/apply",
        component: () => import("@/views/invoice/apply"),
      },
      {
        name: "editInvoice",
        path: "finance/invoice/edit",
        component: () => import("@/views/invoice/edit"),
      },
      {
        path: "finance/invoice/detail",
        component: () => import("@/views/invoice/detail"),
      },
      {
        name: "sharedCost",
        path: "finance/shared-cost",
        component: () => import("@/views/shared-cost"),
      },
      {
        path: "finance/shared-cost/project-detail",
        component: () => import("@/views/shared-cost/project-detail"),
      },
      {
        name: "standingBook",
        path: "finance/standing-book",
        component: () => import("@/views/finance/standing-book"),
      },
      {
        name: "subpackage",
        path: "finance/subpackage",
        component: () => import("@/views/finance/subpackage"),
      },

      // 应收账款
      {
        name: "accounts-receivable",
        path: "finance/accounts-receivable",
        component: () => import("@/views/finance/accounts-receivable"),
      },
      {
        path: "finance/accounts-receivable/detail",
        component: () => import("@/views/finance/accounts-receivable/detail"),
      },
      // 合同资产
      {
        name: "contract-assets",
        path: "finance/contract-assets",
        component: () => import("@/views/finance/contract-assets"),
      },
      {
        path: "finance/contract-assets/detail",
        component: () => import("@/views/finance/contract-assets/detail"),
      },

      // 人力资源：员工管理
      {
        name: "employee",
        path: "human-resources/map/employee",
        component: () => import("@/views/human-resources/employee"),
      },
      {
        name: "addEmployee",
        path: "human-resources/map/employee/add",
        component: () => import("@/views/human-resources/employee/add"),
      },
      {
        name: "editEmployee",
        path: "human-resources/map/employee/edit",
        component: () => import("@/views/human-resources/employee/edit"),
      },

      {
        path: "human-resources/map/employee/detail",
        component: () => import("@/views/human-resources/employee/detail"),
      },
      // 人力资源：标签管理
      {
        name: "tag",
        path: "human-resources/map/tag",
        component: () => import("@/views/human-resources/tag"),
      },
      {
        name: "addTag",
        path: "human-resources/map/tag/add",
        component: () => import("@/views/human-resources/tag/add"),
      },
      {
        name: "editTag",
        path: "human-resources/map/tag/edit",
        component: () => import("@/views/human-resources/tag/edit"),
      },
      // 人力资源：员工标签管理
      {
        name: "employeeTag",
        path: "human-resources/map/employeeTag",
        component: () => import("@/views/human-resources/employeeTag"),
      },
      {
        // 考勤打卡
        path: "human-resources/clock",
        component: () => import("@/views/human-resources/clock"),
      },
      {
        // 请假/公出/出差/出差变更/加班
        path: "human-resources/clock/add",
        component: () => import("@/views/human-resources/clock/add"),
      },
      // 补卡
      {
        path: "human-resources/clock/replace",
        component: () => import("@/views/human-resources/clock/replace"),
      },

      // 考勤管理
      {
        path: "human-resources/timecard/list",
        component: () => import("@/views/human-resources/timecard/index"),
      },
      // 考勤配置
      {
        path: "human-resources/timecard/config",
        component: () => import("@/views/human-resources/timecard/config"),
      },
      // 异常考勤统计
      {
        path: "human-resources/timecard/abnormal",
        component: () => import("@/views/human-resources/timecard/abnormal"),
      },
      // 加班统计
      {
        path: "human-resources/timecard/overtime",
        component: () => import("@/views/human-resources/timecard/overtime"),
      },

      // 出入统计
      {
        path: "human-resources/timecard/access",
        component: () => import("@/views/human-resources/timecard/pass"),
      },
      // 请假/出差/公出查询
      {
        path: "human-resources/timecard/holiday",
        component: () => import("@/views/human-resources/timecard/holiday"),
      },

      {
        path: "human-resources/salary",
        component: () => import("@/views/human-resources/salary"),
      },
      {
        path: "human-resources/performance",
        component: () => import("@/views/human-resources/performance"),
      },

      {
        // 面试管理
        name: "recruit",
        path: "human-resources/recruit",
        component: () => import("@/views/recruit"),
      },
      {
        name: "addRecruit",
        path: "human-resources/recruit/add",
        component: () => import("@/views/recruit/add"),
      },
      {
        name: "editRecruit",
        path: "human-resources/recruit/edit",
        component: () => import("@/views/recruit/edit"),
      },
      {
        path: "human-resources/recruit/invite-hr",
        component: () => import("@/views/recruit/invite-hr"),
      },
      {
        path: "human-resources/recruit/resume",
        component: () => import("@/views/recruit/resume"),
      },

      {
        // name: 'humanResourcesOrg',
        path: "human-resources/map/org",
        component: () => import("@/views/human-resources/org"),
      },
      {
        name: "humanResourcesOrgAdd",
        path: "human-resources/map/org/add",
        component: () => import("@/views/human-resources/org/add"),
      },
      {
        name: "humanResourcesOrgEdit",
        path: "human-resources/map/org/edit",
        component: () => import("@/views/human-resources/org/edit"),
      },
      {
        path: "human-resources/map/org/detail",
        component: () => import("@/views/human-resources/org/detail"),
      },
      {
        path: "human-resources/map/org/group-detail",
        component: () => import("@/views/human-resources/org/group-detail"),
      },
      {
        path: "human-resources/map/org/honor",
        component: () => import("@/views/human-resources/org/honor"),
      },
      {
        path: "human-resources/map/org/group-list",
        component: () => import("@/views/human-resources/org/group-list"),
      },

      // 综合办公：物料管理
      {
        path: "oa/material/index",
        component: () => import("@/views/oa/material"),
      },
      {
        name: "addMaterial",
        path: "oa/material/index/add",
        component: () => import("@/views/oa/material/add"),
      },
      {
        name: "addMaterial",
        path: "oa/material/index/addStorage",
        component: () => import("@/views/oa/material/addStorage"),
      },
      {
        path: "oa/material/index/edit",
        component: () => import("@/views/oa/material/edit"),
      },
      {
        path: "oa/material/apply",
        component: () => import("@/views/oa/material/apply"),
      },
      {
        name: "materialList",
        path: "oa/material/list",
        component: () => import("@/views/oa/material/list"),
      },
      {
        name: "materialDetail",
        path: "oa/material/list/detail",
        component: () => import("@/views/oa/material/detail"),
      },

      // 公告管理
      {
        path: "oa/notice",
        component: () => import("@/views/oa/notice"),
      },
      {
        path: "oa/notice/detail",
        component: () => import("@/views/oa/notice/detail"),
      },
      {
        name: "addNotice",
        path: "oa/notice/add",
        component: () => import("@/views/oa/notice/add"),
      },
      {
        name: "editNotice",
        path: "oa/notice/edit",
        component: () => import("@/views/oa/notice/edit"),
      },

      // 员工天地管理
      {
        path: "oa/staffCulture",
        component: () => import("@/views/oa/staffCulture"),
      },
      {
        path: "oa/staffCulture/detail",
        component: () => import("@/views/oa/staffCulture/detail"),
      },
      {
        name: "addStaffCulture",
        path: "oa/staffCulture/add",
        component: () => import("@/views/oa/staffCulture/add"),
      },
      {
        name: "editStaffCulture",
        path: "oa/staffCulture/edit",
        component: () => import("@/views/oa/staffCulture/edit"),
      },
      
      // 质量安全管理
      {
        path: "oa/quality",
        component: () => import("@/views/oa/quality"),
      },
      {
        path: "oa/quality/detail",
        component: () => import("@/views/oa/quality/detail"),
      },
      {
        name: "addQuality",
        path: "oa/quality/add",
        component: () => import("@/views/oa/quality/add"),
      },
      {
        name: "editQuality",
        path: "oa/quality/edit",
        component: () => import("@/views/oa/quality/edit"),
      },
      // 综合办公：督办管理
      {
        name: "supervise",
        path: "oa/supervise",
        component: () => import("@/views/supervise"),
      },
      {
        name: "addSupervise",
        path: "oa/supervise/add",
        component: () => import("@/views/supervise/add"),
      },
      {
        path: "oa/supervise/detail",
        component: () => import("@/views/supervise/detail"),
      },

      // 综合办公：会议管理
      {
        name: "meeting",
        path: "oa/meeting",
        component: () => import("@/views/meeting"),
      },
      {
        name: "addMeeting",
        path: "oa/meeting/add",
        component: () => import("@/views/meeting/add"),
      },
      {
        name: "editMeeting",
        path: "oa/meeting/edit",
        component: () => import("@/views/meeting/edit"),
      },
      // 综合办公：邮件管理
      {
        name: "meeting",
        path: "oa/mail",
        component: () => import("@/views/mail"),
      },
      // 审批：审批任务和起草中心
      {
        path: "approval/center",
        component: () => import("@/views/approval/center"),
      },
      {
        name: "approvalTask",
        path: "approval/task",
        component: () => import("@/views/approval/task"),
      },
      {

        path: "approval/task/transfer",
        component: () => import("@/views/approval/task/transfer"),
      },
      {
        name: "approvalTaskDetail",
        path: "approval/task/detail",
        component: () => import("@/views/approval/task/detail"),
      },
      // 综合办公，兼容原来的路径，目的是原来的书签还能用
      {
        path: "oa/approval",
        redirect: "approval/task",
      },
      {
        path: "oa/approval/detail",
        redirect: "approval/task/detail",
      },

      {
        path: "oa/doc",
        component: () => import("@/views/oa/doc"),
      },
      {
        name: "addDoc",
        path: "oa/doc/add",
        component: () => import("@/views/oa/doc/add"),
      },

      {
        path: "/oa/archive/query",
        component: () => import("@/views/archives/index"),
      },
      {
        path: "/oa/archive/query/lend",
        component: () => import("@/views/archives/lend"),
      },
      {
        path: "/oa/archive/query/transfer",
        component: () => import("@/views/archives/transfer"),
      },
      {
        path: "/oa/archive/query/detail",
        component: () => import("@/views/archives/detail"),
      },
      {
        path: "/oa/archive/account",
        component: () => import("@/views/archives/list"),
      },
      {
        path: "/oa/archive/account/add",
        component: () => import("@/views/archives/add"),
      },
      {
        path: "/oa/archive/account/edit",
        component: () => import("@/views/archives/edit"),
      },

      {
        path: "/oa/meal",
        component: () => import("@/views/oa/meal"),
      },
      {
        path: "/oa/meal/add",
        component: () => import("@/views/oa/meal/add"),
      },
      {
        path: "/oa/meal/add-overtime",
        component: () => import("@/views/oa/meal/add-overtime"),
      },
      {
        path: "/oa/meal-card",
        component: () => import("@/views/oa/meal-card"),
      },
      {
        path: "/oa/meal-card/apply",
        component: () => import("@/views/oa/meal-card/apply"),
      },

      {
        path: "/oa/face",
        component: () => import("@/views/oa/face"),
      },
      {
        path: "/oa/car",
        component: () => import("@/views/oa/car"),
      },
      // 系统设置
      {
        path: "setting/module",
        component: () => import("@/views/setting/module"),
      },
      {
        name: "addModule",
        path: "setting/module/add",
        component: () => import("@/views/setting/module/add"),
      },
      {
        name: "editModule",
        path: "setting/module/edit",
        component: () => import("@/views/setting/module/edit"),
      },
      {
        path: "setting/permission",
        component: () => import("@/views/setting/permission"),
      },
      {
        name: "addPermission",
        path: "setting/permission/add",
        component: () => import("@/views/setting/permission/add"),
      },
      {
        name: "editPermission",
        path: "setting/permission/edit",
        component: () => import("@/views/setting/permission/edit"),
      },

      {
        path: "setting/menu",
        component: () => import("@/views/setting/menu"),
      },
      {
        name: "addMenu",
        path: "setting/menu/add",
        component: () => import("@/views/setting/menu/add"),
      },
      {
        name: "editMenu",
        path: "setting/menu/edit",
        component: () => import("@/views/setting/menu/edit"),
      },

      {
        path: "setting/role",
        component: () => import("@/views/setting/role"),
      },
      {
        name: "addRole",
        path: "setting/role/add",
        component: () => import("@/views/setting/role/add"),
      },
      {
        name: "editRole",
        path: "setting/role/edit",
        component: () => import("@/views/setting/role/edit"),
      },

      {
        path: "setting/user",
        component: () => import("@/views/setting/user"),
      },
      {
        name: "addUser",
        path: "setting/user/add",
        component: () => import("@/views/setting/user/add"),
      },
      {
        name: "editUser",
        path: "setting/user/edit",
        component: () => import("@/views/setting/user/edit"),
      },
      {
        path: "setting/data-dict",
        component: () => import("@/views/setting/data-dict"),
      },
      {
        name: "addDataDict",
        path: "setting/data-dict/add",
        component: () => import("@/views/setting/data-dict/add"),
      },
      {
        name: "editDataDict",
        path: "setting/data-dict/edit",
        component: () => import("@/views/setting/data-dict/edit"),
      },
      {
        path: "setting/company",
        component: () => import("@/views/setting/company"),
      },
      {
        name: "addCompany",
        path: "setting/company/add",
        component: () => import("@/views/setting/company/add"),
      },
      {
        name: "editCompany",
        path: "setting/company/edit",
        component: () => import("@/views/setting/company/edit"),
      },
      {
        path: "setting/area",
        component: () => import("@/views/setting/area"),
      },
      {
        name: "addArea",
        path: "setting/area/add",
        component: () => import("@/views/setting/area/add"),
      },
      {
        name: "editArea",
        path: "setting/area/edit",
        component: () => import("@/views/setting/area/edit"),
      },

      {
        path: "setting/meeting-room",
        component: () => import("@/views/setting/meeting-room"),
      },
      {
        name: "addMeetingRoom",
        path: "setting/meeting-room/add",
        component: () => import("@/views/setting/meeting-room/add"),
      },
      {
        name: "editMeetingRoom",
        path: "setting/meeting-room/edit",
        component: () => import("@/views/setting/meeting-room/edit"),
      },

      {
        path: "setting/app-version",
        component: () => import("@/views/setting/app-version"),
      },
      {
        name: "addAppVersion",
        path: "setting/app-version/add",
        component: () => import("@/views/setting/app-version/add"),
      },

      {
        path: "setting/ai-version",
        component: () => import("@/views/setting/ai-version"),
      },
      {
        path: "setting/ai-version/add",
        component: () => import("@/views/setting/ai-version/add"),
      },
      {
        path: "setting/ai-version/edit",
        component: () => import("@/views/setting/ai-version/edit"),
      },

      {
        path: "setting/api-log",
        component: () => import("@/views/setting/api-log"),
      },
      {
        path: "setting/log",
        component: () => import("@/views/setting/log"),
      },
      {
        path: "setting/face",
        component: () => import("@/views/setting/face"),
      },
      {
        path: "setting/approval",
        component: () => import("@/views/setting/approval"),
      },

      {
        path: "bidding/company",
        redirect: "market/bidding/company",
      },
      {
        path: "market/bidding/company",
        component: () => import("@/views/bidding/company"),
      },
      {
        path: "bidding/company/detail",
        redirect: "market/bidding/company/detail",
      },
      {
        path: "market/bidding/company/detail",
        component: () => import("@/views/bidding/company/detail"),
      },
      {
        path: "bidding/index",
        redirect: "market/bidding/index",
      },
      {
        path: "market/bidding/index",
        component: () => import("@/views/bidding"),
      },
      {
        path: "bidding/index/add",
        redirect: "market/bidding/index/add",
      },
      {
        name: "addBidding",
        path: "market/bidding/index/add",
        component: () => import("@/views/bidding/add"),
      },
      {
        path: "bidding/index/edit",
        redirect: "market/bidding/index/edit",
      },
      {
        name: "editBidding",
        path: "market/bidding/index/edit",
        component: () => import("@/views/bidding/edit"),
      },
      {
        path: "bidding/index/detail",
        redirect: "market/bidding/index/detail",
      },
      {
        path: "market/bidding/index/detail",
        component: () => import("@/views/bidding/detail"),
      },
      {
        path: "bidding/index/evaluation/select-user",
        redirect: "market/bidding/index/evaluation/select-user",
      },
      {
        path: "market/bidding/index/evaluation/select-user",
        component: () => import("@/views/bidding/evaluation/select-user"),
      },
      {
        path: "bidding/index/evaluation",
        redirect: "market/bidding/index/evaluation",
      },
      {
        path: "market/bidding/index/evaluation",
        component: () => import("@/views/bidding/evaluation/jury-evaluation"),
      },
      {
        path: "bidding/index/evaluation/view",
        redirect: "market/bidding/index/evaluation/view",
      },
      {
        path: "market/bidding/index/evaluation/view",
        component: () => import("@/views/bidding/evaluation/view"),
      },
      {
        path: "bidding/index/evaluation/summary",
        redirect: "market/bidding/index/evaluation/summary",
      },
      {
        path: "market/bidding/index/evaluation/summary",
        component: () => import("@/views/bidding/evaluation/summary"),
      },
      {
        path: "bidding/index/publicity",
        redirect: "market/bidding/index/publicity",
      },
      {
        path: "market/bidding/index/publicity",
        component: () => import("@/views/bidding/publicity"),
      },
      {
        path: "bidding/article",
        redirect: "market/bidding/article",
      },
      {
        path: "market/bidding/article",
        component: () => import("@/views/bidding/article"),
      },
      {
        path: "bidding/article/add",
        redirect: "market/bidding/article/add",
      },
      {
        name: "addBiddingArticle",
        path: "market/bidding/article/add",
        component: () => import("@/views/bidding/article/add"),
      },
      {
        path: "bidding/article/edit",
        redirect: "market/bidding/article/edit",
      },
      {
        name: "editBiddingArticle",
        path: "market/bidding/article/edit",
        component: () => import("@/views/bidding/article/edit"),
      },

      // 通用分享页面
      {
        path: "share",
        component: () => import("@/views/share"),
        meta: {
          isPublic: true,
        },
      },

      // 参数化系统
      // 公共参数配置页面
      {
        path: "lab/parameterization/public-params",
        component: () => import("@/views/parameterization/public-params"),
      },
      // 计算书
      {
        path: "lab/parameterization/book",
        component: () => import("@/views/parameterization/book"),
      },
      {
        name: "addParamBook",
        path: "lab/parameterization/book/add",
        component: () => import("@/views/parameterization/book/add"),
      },
      {
        name: "editParamBook",
        path: "lab/parameterization/book/edit",
        component: () => import("@/views/parameterization/book/edit"),
      },
      {
        name: "paramBookDetail",
        path: "lab/parameterization/book/detail",
        component: () => import("@/views/parameterization/book/detail"),
      },

      {
        path: "lab/parameterization/task",
        component: () => import("@/views/parameterization/task"),
      },
      {
        path: "lab/parameterization/task/select",
        component: () => import("@/views/parameterization/task/select"),
      },
      {
        name: "addParamTask",
        path: "lab/parameterization/task/add",
        component: () => import("@/views/parameterization/task/add"),
      },
      {
        path: "lab/parameterization/task/detail",
        component: () => import("@/views/parameterization/task/detail"),
      },

      // 官网管理
      {
        path: "website/news",
        redirect: 'oa/website/news'
      },
      {
        path: "oa/website/news",
        component: () => import("@/views/website/news"),
      },
      {
        path: "website/news/add",
        redirect: 'oa/website/news/add'
      },
      {
        name: "websiteNewsAdd",
        path: "oa/website/news/add",
        component: () => import("@/views/website/news/add"),
      },
      {
        path: "website/news/edit",
        redirect: 'oa/website/news/edit'
      },
      {
        name: "websiteNewsEdit",
        path: "oa/website/news/edit",
        component: () => import("@/views/website/news/edit"),
      },
      {
        path: "website/case",
        redirect: 'oa/website/case'
      },
      {
        path: "oa/website/case",
        component: () => import("@/views/website/case"),
      },
      {
        path: "website/case/add",
        redirect: 'oa/website/case/add'
      },
      {
        name: "websiteCaseAdd",
        path: "oa/website/case/add",
        component: () => import("@/views/website/case/add"),
      },
      {
        path: "website/case/edit",
        redirect: 'oa/website/case/edit'
      },
      {
        name: "websiteCaseEdit",
        path: "oa/website/case/edit",
        component: () => import("@/views/website/case/edit"),
      },
      {
        path: "website/job",
        redirect: 'oa/website/job'
      },
      {
        path: "oa/website/job",
        component: () => import("@/views/website/job"),
      },
      {
        path: "website/job/add",
        redirect: 'oa/website/job/add'
      },
      {
        name: "websiteJobAdd",
        path: "oa/website/job/add",
        component: () => import("@/views/website/job/add"),
      },
      {
        path: "website/job/edit",
        redirect: 'oa/website/job/edit'
      },
      {
        name: "websiteJobEdit",
        path: "oa/website/job/edit",
        component: () => import("@/views/website/job/edit"),
      },
      {
        path: "website/feedback",
        redirect: 'oa/website/feedback'
      },
      {
        path: "oa/website/feedback",
        component: () => import("@/views/website/feedback"),
      },
      // epc项目
      {
        path: "epc/project",
        component: () => import("@/views/epc/project"),
      },
      {
        path: "epc/project/add",
        name: "projAdd",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/project/add"),
      },
      {
        path: "epc/project/update",
        name: "projUpdate",
        component: () => import("@/views/epc/project/edit"),
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
      },
      {
        path: "epc/project/detail",
        name: "epcDetail",
        component: () => import("@/views/epc/project/detail"),
      },
      {
        path: "epc/project/budget",
        name: "projBudegt",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/project/budget"),
      },
      {
        path: "epc/project/view",
        component: () => import("@/views/epc/project/view"),
      },
      {
        path: "epc/project/mainContractAssign",
        name: "purchaseMCA",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/project/main-contract-assign"),
      },
      {
        path: "epc/project/mainContractImplement",
        name: "purchaseMain",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/project/main-contract-implement"),
      },

      // 策划阶段
      {
        path: "epc/plan/manageObjective",
        name: "planManObj",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/plan/manageObjective"),
      },
      {
        path: "epc/plan/managePlan",
        name: "planManPlan",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/plan/manage-plan"),
      },
      {
        path: "epc/plan/constructionSchedule",
        name: "planConstru",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/plan/construction-schedule"),
      },
      {
        path: "epc/plan/budget",
        name: "planBudget",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/plan/budget/index"),
      },
      // 设计阶段
      {
        path: "epc/design/ChangeApproval",
        name: "designChange",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/design/ChangeApproval"),
      },
      {
        path: "epc/design/ResultConfirm",
        name: "designRes",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/design/ResultConfirm"),
      },
      {
        path: "epc/design/DisclosureReport",
        name: "designDis",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/design/DisclosureManual"),
      },
      {
        path: "epc/design/CompletionReport",
        name: "designCom",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/design/CompletionReport"),
      },
      // 施工阶段
      {
        path: "epc/construction/organizeDesign",
        name: "construOrg",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/construction/organize-design"),
      },
      {
        path: "epc/construction/commenceReview",
        name: "construCom",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/construction/commence-review"),
      },
      {
        path: "epc/construction/process",
        name: "construPro",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/construction/process"),
      },
      {
        path: "epc/construction/qualityInspect",
        name: "construQua",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/construction/quality-inspect"),
      },
      {
        path: "epc/construction/safety",
        name: "construSaf",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/construction/safety"),
      },
      {
        path: "epc/construction/dataInspect",
        name: "construData",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/construction/data-inspect"),
      },
      {
        path: "epc/construction/claim",
        name: "construClaim",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/construction/claim"),
      },
      {
        path: "epc/construction/visa",
        name: "construcVisa",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/construction/visa"),
      },
      {
        path: "epc/construction/accident",
        name: "construAcci",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/construction/accident"),
      },
      {
        path: "epc/construction/violation",
        name: "ConstruVio",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/construction/violation"),
      },
      // 采购阶段
      {
        path: "epc/purchase/masterPlan",
        name: "purchaseMaster",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/purchase/master-plan"),
      },
      {
        path: "epc/purchase/bidding",
        name: "purchaseBid",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/purchase/bidding"),
      },
      {
        path: "epc/purchase/singleSource",
        name: "purchaseSing",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/purchase/single-source"),
      },
      {
        path: "epc/purchase/inquiry",
        name: "purchaseInq",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/purchase/inquiry"),
      },
      {
        path: "epc/purchase/emergancyPurchase",
        name: "purchaseEmergan",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/purchase/emergancy-purchase"),
      },
      {
        path: "epc/purchase/transfer",
        name: "purchaseTransfer",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/purchase/transfer"),
      },
      {
        path: "epc/purchase/returnToFactory",
        name: "purchaseReturn",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/purchase/return-to-factory"),
      },
      {
        path: "epc/purchase/subpackageContract",
        name: "purchaseSub",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/purchase/subpackage-contract"),
      },
      // 收支阶段
      {
        path: "epc/incomeExpense/capital",
        name: "incExpCap",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/income-expense/capital"),
        beforeEnter: (to, from, next) => {
          if (to.query.activeKey === "0") {
            //若进入收支台账页面
            fetchDetail({ pid: to.query.pid }).then((res) => {
              console.log(res);
              if (res && res?.code === 400) {
                next(false);
                return;
              } else {
                next();
              }
            });
          } else {
            next();
          }
          // next(false)   next();  next('/403')
        },
      },
      {
        path: "epc/incomeExpense/completeSettlement",
        name: "incExpComSettle",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () =>
          import("@/views/epc/income-expense/complete-settlement"),
      },
      {
        path: "epc/incomeExpense/subpackageSettlement",
        name: "incExpSub",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () =>
          import("@/views/epc/income-expense/subpackage-settlement"),
      },
      {
        path: "epc/incomeExpense/costExecutionReport",
        name: "incExpCost",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () =>
          import("@/views/epc/income-expense/cost-execution-report"),
      },
      {
        path: "epc/incomeExpense/surplusMaterialDisposal",
        name: "incExpSur",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () =>
          import("@/views/epc/income-expense/surplus-material-disposal"),
      },
      // 收尾阶段
      {
        path: "epc/complete/end",
        name: "completeEnd",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/complete/end"),
      },
      {
        path: "epc/complete/performanceAssessment",
        name: "completePer",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/complete/performance-assessment"),
      },
      {
        path: "epc/complete/report",
        name: "completeReport",
        meta: {
          alertWhenLeaving: true, // 关闭网页时需要提示
        },
        component: () => import("@/views/epc/complete/report"),
      },

      // epc 项目汇总
      {
        name: 'epcProjectSummary',
        path: "epc/project-summary",
        component: () => import("@/views/epc/project-summary"),
      },
      {
        path: "epc/project-summary/add",
        component: () => import("@/views/epc/project-summary/add"),
      },
      {
        path: "epc/project-summary/edit",
        component: () => import("@/views/epc/project-summary/edit"),
      },
      {
        path: "epc/project-summary/detail",
        component: () => import("@/views/epc/project-summary/detail"),
      },

      // 财务核算
      {
        path: "epc/financial-accounting",
        component: () => import("@/views/epc/financial-accounting"),
      },
      // 业务核算
      {
        path: "epc/business-accounting",
        component: () => import("@/views/epc/business-accounting"),
      },



      // epc利润管理
      {
        path: "epc/profit",
        component: () => import("@/views/epc/profit"),
      },
      {
        name: 'epc_profit_edit',
        path: "epc/profit/edit",
        component: () => import("@/views/epc/profit/edit"),
      },
      {
        path: "epc/profit/detail",
        component: () => import("@/views/epc/profit/detail"),
      },

      // epc进度管理
      {
        path: "epc/process",
        component: () => import("@/views/epc/process"),
      },
      {
        path: "epc/process/plan-fill",
        component: () => import("@/views/epc/process/plan-fill"),
      },
      {
        path: "epc/process/plan-management",
        component: () => import("@/views/epc/process/plan-management"),
      },

      // 课程管理
      {
        path: "knowledge/course",
        component: () => import("@/views/knowledge/course"),
      },
      {
        name: 'courseAdd',
        path: "knowledge/course/add",
        component: () => import("@/views/knowledge/course/add"),
      },
      {
        name: 'courseEdit',
        path: "knowledge/course/edit",
        component: () => import("@/views/knowledge/course/edit"),
      },

      // 目录管理
      {
        path: "knowledge/directory",
        component: () => import("@/views/knowledge/directory"),
      },

      // 课程列表（用户查询）
      {
        name: 'study',
        path: "knowledge/study",
        component: () => import("@/views/knowledge/study"),
      },
      // 我的课程/考试/收藏
      {
        path: "knowledge/study/me",
        component: () => import("@/views/knowledge/study/me"),
      },
      // 我的课程/考试/收藏详情
      {
        path: "knowledge/study/detail",
        component: () => import("@/views/knowledge/study/detail"),
      },
      // 正在学习页面
      {
        path: "knowledge/study/play",
        component: () => import("@/views/knowledge/study/play"),
      },

      // 考试中心
      {
        path: "knowledge/exam/index",
        component: () => import("@/views/exam"),
      },
      {
        name: "addExam",
        path: "knowledge/exam/index/add",
        component: () => import("@/views/exam/add"),
      },
      {
        name: "editExam",
        path: "knowledge/exam/index/edit",
        component: () => import("@/views/exam/edit"),
      },
      {
        name: "startExam",
        path: "knowledge/exam/index/start",
        component: () => import("@/views/exam/start"),
      },

      {
        path: "knowledge/exam/management",
        component: () => import("@/views/exam/management"),
      },
      {
        path: "knowledge/exam/management/review",
        component: () => import("@/views/exam/review"),
      },

      {
        path: "knowledge/in",
        redirect: "knowledge/lib/in",
      },
      {
        name: "inKnowledge",
        path: "knowledge/lib/in",
        component: () => import("@/views/knowledge/in"),
      },
      {
        path: "knowledge/in/add",
        redirect: "knowledge/lib/in/add",
      },
      {
        name: "addKnowledge",
        path: "knowledge/lib/in/add",
        component: () => import("@/views/knowledge/in/add"),
      },
      {
        name: "addBrand",
        path: "knowledge/lib/in/add-brand",
        component: () => import("@/views/knowledge/in/add-brand"),
      },
      {
        name: "addProduction",
        path: "knowledge/lib/in/add-production",
        component: () => import("@/views/knowledge/in/add-production"),
      },
      {
        path: "knowledge/in/edit",
        redirect: "knowledge/lib/in/edit",
      },
      {
        name: "editKnowledge",
        path: "knowledge/lib/in/edit",
        component: () => import("@/views/knowledge/in/edit"),
      },
      {
        name: "editBrand",
        path: "knowledge/lib/in/edit-brand",
        component: () => import("@/views/knowledge/in/edit-brand"),
      },
      {
        name: "editProduction",
        path: "knowledge/lib/in/edit-production",
        component: () => import("@/views/knowledge/in/edit-production"),
      },
      {
        path: "knowledge/in/detail",
        redirect: "knowledge/lib/in/detail",
      },
      {
        path: "knowledge/lib/in/detail",
        component: () => import("@/views/knowledge/in/detail"),
      },


      {
        name: "think",
        path: "knowledge/lib/think",
        component: () => import("@/views/knowledge/think"),
      },
      {
        name: "thinkAdd",
        path: "knowledge/lib/think/add",
        component: () => import("@/views/knowledge/think/add"),
      },
      {
        name: "thinkEdit",
        path: "knowledge/lib/think/edit",
        component: () => import("@/views/knowledge/think/edit"),
      },
      {
        path: "knowledge/lib/think/preview",
        component: () => import("@/views/knowledge/think/preview"),
      },

      {
        path: "knowledge/lib/out",
        component: () => import("@/views/knowledge/out"),
      },

      {
        path: "knowledge/resource",
        component: () => import("@/views/knowledge/resource"),
      },
      {
        path: "knowledge/resource/add",
        component: () => import("@/views/knowledge/resource/add"),
      },
      {
        path: "knowledge/resource/detail",
        component: () => import("@/views/knowledge/resource/detail"),
      },
      {
        path: "party/public/notice",
        component: () => import("@/views/party/notice"),
      },
      {
        name: "addPartyNotice",
        path: "party/public/notice/add",
        component: () => import("@/views/party/notice/add"),
      },

      {
        name: "editPartyNotice",
        path: "party/public/notice/edit",
        component: () => import("@/views/party/notice/edit"),
      },

      {
        path: "party/public/notice/detail",
        component: () => import("@/views/party/notice/detail"),
      },
      {
        path: "party/public/honest",
        component: () => import("@/views/party/honest"),
      },
      {
        name: "addPartyHonest",
        path: "party/public/honest/add",
        component: () => import("@/views/party/honest/add"),
      },

      {
        name: "editPartyHonest",
        path: "party/public/honest/edit",
        component: () => import("@/views/party/honest/edit"),
      },

      {
        path: "party/public/honest/detail",
        component: () => import("@/views/party/honest/detail"),
      },


      {
        path: "party/public/letter",
        component: () => import("@/views/party/letter"),
      },
      {
        path: "party/public/letter/add",
        component: () => import("@/views/party/letter/add"),
      },
      {
        path: "party/public/letter/detail",
        component: () => import("@/views/party/letter/detail"),
      },

      {
        path: "technical-quality/score",
        component: () => import("@/views/technical-quality/score"),
      },
      {
        name: "technicalQualityScoreAdd",
        path: "technical-quality/score/add",
        component: () => import("@/views/technical-quality/score/add"),
      },
      {
        name: "technicalQualityScoreEdit",
        path: "technical-quality/score/edit",
        component: () => import("@/views/technical-quality/score/edit"),
      },
      {
        path: "technical-quality/score/detail",
        component: () => import("@/views/technical-quality/score/detail"),
      },
      {

        path: "technical-quality/report",
        component: () => import("@/views/technical-quality/report"),
      },
      {
        path: "technical-quality/report/add",
        component: () => import("@/views/technical-quality/report/add"),
      },
      {
        path: "technical-quality/report/edit",
        component: () => import("@/views/technical-quality/report/edit"),
      },
      {
        path: "technical-quality/report/history",
        component: () => import("@/views/technical-quality/report/history"),
      },
      {
        path: "technical-quality/report/detail",
        component: () => import("@/views/technical-quality/report/detail"),
      },

      {
        path: "technical-quality/report/table",
        component: () => import("@/views/technical-quality/report/table"),
      },


      {
        path: "technical-quality/security-check-management/security-check",
        component: () => import("@/views/technical-quality/security-check"),
      },
      {
        path: "technical-quality/security-check-management/security-check/add",
        component: () => import("@/views/technical-quality/security-check/add"),
      },
      {
        path: "technical-quality/security-check-management/security-check/detail",
        component: () => import("@/views/technical-quality/security-check/detail"),
      },

      {
        path: "technical-quality/security-check-management/security-check/book",
        component: () => import("@/views/technical-quality/security-check/book"),
      },

      {
        path: "technical-quality/security-check-management/security-check/book/edit",
        component: () => import("@/views/technical-quality/security-check/book/edit"),
      },
      {
        path: "technical-quality/security-check-management/security-check/project",
        component: () => import("@/views/technical-quality/security-check/project"),
      },

      {
        path: "technical-quality/security-check-management/security-check/duty",
        component: () => import("@/views/technical-quality/security-check/duty"),
      },

      {
        path: "technical-quality/emergency/drill",
        component: () => import("@/views/technical-quality/emergency/drill"),
      },

      {
        path: "technical-quality/accident/list",
        component: () => import("@/views/technical-quality/accident/list"),
      },
      {
        name: 'accidentAdd',
        path: "technical-quality/accident/list/add",
        component: () => import("@/views/technical-quality/accident/list/add"),
      },
      {
        name: 'accidentEdit',
        path: "technical-quality/accident/list/edit",
        component: () => import("@/views/technical-quality/accident/list/edit"),
      },
      {
        path: "technical-quality/accident/list/detail",
        component: () => import("@/views/technical-quality/accident/list/detail"),
      },

      {
        path: "technical-quality/accident/standing-book",
        component: () => import("@/views/technical-quality/accident/standing-book"),
      },
      {
        path: "technical-quality/quality-import/budget-list",
        component: () => import("@/views/technical-quality/quality-import/budget-list"),
      },
      {
        path: "technical-quality/quality-import/budget-list/add",
        component: () => import("@/views/technical-quality/quality-import/budget-list/add"),
      },
      {
        path: "technical-quality/quality-import/budget-list/edit",
        component: () => import("@/views/technical-quality/quality-import/budget-list/edit"),
      },
      {
        path: "technical-quality/quality-import/budget-list/detail",
        component: () => import("@/views/technical-quality/quality-import/budget-list/detail"),
      },
      {
        path: "technical-quality/quality-import/money-list",
        component: () => import("@/views/technical-quality/quality-import/money-list"),
      },
      {
        path: "technical-quality/quality-import/money-list/add",
        component: () => import("@/views/technical-quality/quality-import/money-list/add"),
      },
      {
        path: "technical-quality/quality-import/money-list/edit",
        component: () => import("@/views/technical-quality/quality-import/money-list/edit"),
      },
      {
        path: "technical-quality/quality-import/money-list/detail",
        component: () => import("@/views/technical-quality/quality-import/money-list/detail"),
      },
      {
        path: "technical-quality/safeProduction",
        component: () => import("@/views/technical-quality/safeProduction"),
      },
      {
        path: "technical-quality/safeProduction/list",
        component: () => import("@/views/technical-quality/safeProduction/list"),
      },
      {
        path: "technical-quality/riskManagement/estimate",
        component: () => import("@/views/technical-quality/riskManagement/estimate"),
      },
      {
        path: "technical-quality/riskManagement/estimate/edit",
        component: () => import("@/views/technical-quality/riskManagement/estimate/edit"),
      },
      {
        path: "technical-quality/riskManagement/estimate/detail",
        component: () => import("@/views/technical-quality/riskManagement/estimate/detail"),
      },

      {
        path: "technical-quality/safeDailyWork/meeting",
        component: () => import("@/views/technical-quality/safeDailyWork/meeting"),
      },
      {
        path: "technical-quality/safeDailyWork/meeting/add",
        component: () => import("@/views/technical-quality/safeDailyWork/meeting/add"),
      },
      {
        path: "technical-quality/safeDailyWork/meeting/edit",
        component: () => import("@/views/technical-quality/safeDailyWork/meeting/edit"),
      },
      {
        path: "technical-quality/safeDailyWork/meeting/detail",
        component: () => import("@/views/technical-quality/safeDailyWork/meeting/detail"),
      },
      {
        path: "technical-quality/safeDailyWork/issue",
        component: () => import("@/views/technical-quality/safeDailyWork/issue"),
      },
      {
        path: "technical-quality/safeDailyWork/issue/add",
        component: () => import("@/views/technical-quality/safeDailyWork/issue/add"),
      },
      {
        path: "technical-quality/safeDailyWork/issue/edit",
        component: () => import("@/views/technical-quality/safeDailyWork/issue/edit"),
      },
      {
        path: "technical-quality/safeDailyWork/issue/detail",
        component: () => import("@/views/technical-quality/safeDailyWork/issue/detail"),
      },
      {
        path: "technical-quality/safeDailyWork/duty",
        component: () => import("@/views/technical-quality/safeDailyWork/duty"),
      },
      {
        path: "technical-quality/safeDailyWork/duty/add",
        component: () => import("@/views/technical-quality/safeDailyWork/duty/add"),
      },
      {
        path: "technical-quality/safeDailyWork/duty/edit",
        component: () => import("@/views/technical-quality/safeDailyWork/duty/edit"),
      },
      {
        path: "technical-quality/safeDailyWork/duty/detail",
        component: () => import("@/views/technical-quality/safeDailyWork/duty/detail"),
      },
      {
        path: "technical-quality/riskManagement/task",
        component: () => import("@/views/technical-quality/riskManagement/task"),
      },
      {
        path: "technical-quality/riskManagement/task/add",
        component: () => import("@/views/technical-quality/riskManagement/task/add"),
      },
      {
        path: "technical-quality/riskManagement/task/edit",
        component: () => import("@/views/technical-quality/riskManagement/task/edit"),
      },
      {
        path: "technical-quality/riskManagement/task/detail",
        component: () => import("@/views/technical-quality/riskManagement/task/detail"),
      },
      {
        path: "technical-quality/riskManagement/task/list",
        component: () => import("@/views/technical-quality/riskManagement/task/list"),
      },
      {
        path: "lab/ai/log",
        component: () => import("@/views/ai/log.vue"),
      },

      {
        path: "collaborate",
        component: () => import("@/views/collaborate/index.vue"),
      },

      {
        path: "chatgpt",
        component: () => import("@/views/chatgpt/index.vue"),
      },

      {
        path: "test",
        component: () => import("@/views/test/index.vue"),
        meta: {
          isPublic: true,
        }
      },


      {
        path: "403",
        component: () => import("@/views/403"),
        meta: {
          isPublic: true,
        },
      },
      {
        path: "*",
        component: () => import("@/views/404"),
        meta: {
          isPublic: true,
        },
      },
    ],
  },
];
